import './style.css'

function TechSlider(props) {

    return (
        <div className='slider'>
            <span>Technologies I've worked with</span> 
            <div className='tech-slider'>
                <img src="react.svg" alt="React Logo" />
                <img src="Python.svg" alt="Go Logo" />
                <img src="js.svg" alt="JavaScript Logo" />
                <img src="Go.svg" alt="Go Logo" />
                <img src="gcp.svg" alt="Google cloud Logo" />
                <img src="matlab.svg" alt="MATLAB Logo" />
                <img src="html.svg" alt="OpenCV Logo" />
                <img src="css.svg" alt="OpenCV Logo" />
                <img src="figma.svg" alt="OpenCV Logo" />
            </div>
        </div>
    );
}

export default TechSlider;