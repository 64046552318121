import { Link } from 'react-router-dom';

import './style.css'

function Presentation(props) {

  return (
    <div className="pres" id="presentation">
        <div className="Luna">
          <img src="luna.png" alt="an image of my white cat luna." />
        </div>
        <div className="vl"></div>
        <div className="pres-text">
          <div className="pres-text-body-first">
            <div className="pres-text-title">
              Hi I am Cristian Gutiérrez and this is my cat Luna.
            </div>
            <div className="pres-text-body">
              Sounds like a good presentation huh?
              <br></br>
              <p>I am a final year student of Computer Science (UAB) interested in the Web, from the UI side of things to the core systems that sustent it. I also enjoy Computer Vision in a classical approach, before CNNs came by like a hurricane.</p>
              <p>Recently I've picked up Go as my main language because its simplicity and not falling into neither the low or high level languages.</p>
            </div>
          </div>
          <Link to="/about" className="pres-text-arrow-pres">
            <div className="main-title-learn-more-text">
                Learn more about Cristian
            </div>
            <img src={props.darkMode ? "arrow-down-btn.svg" : "arrow-down-btn-light.svg"} alt="image of a sphere"/>
          </Link>
        </div>
    </div>
  );
}

export default Presentation;