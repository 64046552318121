import { useState } from 'react';
import Topic from '../Topic';

import './style.css'

function Topics(props) {
    const [btn, setBtn] = useState('all');

    const handleTopicClick = (name) => {
        if(name === btn) {
            name = 'all';
        }
        props.btnState(name)
        setBtn(name)
    };

    
    return (
        <div className="blog-topics">
            <h1 className="topics-title">
            {props.title}
            </h1>
            <div className="topics-bot">
                {/* {
                    btn === ''
                    ? null
                    :
                    <div className="topics-x">
                        <img src={props.darkMode ? "x.png" : "x-light.png"}  onClick={() => handleTopicClick('')}/>
                    </div>
                } */}
                <div className="topics-tags">
                { props.topics
                    ? // onClick={event => handleTopicClick(event, e)}
                    props.topics.map((e,key) =>
                        <Topic label={e} key={key} handle={() => handleTopicClick(e)} btn={btn}/>
                    )
                    :
                    null
                }
                </div>
            </div>
        </div>
    );
}

export default Topics;