import BlogCard from '../BlogCard';
import './style.css'

function  BlogList(props) {
  
  var options = { weekday: 'short', year: 'numeric', month: 'long', day: 'numeric' };

  var data;

  if(props.btnClicked === '' || props.btnClicked === 'all') {
      data = props.blogs
  } else {
      data = props.blogs.filter(e => e.topics.includes(props.btnClicked) === true);
  }

  return (
    
      <div className="blog-list">  
          { data
          ? 
          data.map((e,key) =>
            <BlogCard e={e} featured={false} imgname={e.image.file.url} key={key} date={new Date(e.date).toLocaleDateString("en-UK", options)} title={e.title} />
          )
          :
          null
        }
      </div>
  );
}

export default BlogList;
