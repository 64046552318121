import { useState } from "react";
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { Analytics } from '@vercel/analytics/react';

import './style.css'

// hooks
import ScrollToTop from '../hooks/ScrollToTop';

// components
import Nav from "../components/Nav"
import Presentation from "../components/home/Presentation";
import BlogPost from '../components/blog/BlogPost';
import ProjectPost from '../components/projects/ProjectPost';
import FeaturedBlogList from "../components/blog/FeaturedBlogList";
import FeaturedProjectList from "../components/projects/FeaturedProjectList";
import TechSlider from "../components/home/TechSlider";
import Footer from "../components/Footer";

// src/pages
import Main from "../pages/Main"
import Blog from "../pages/Blog";
import Projects from "../pages/Projects";
import About from "../pages/About";
import Contact from "../pages/Contact";

import { inject } from '@vercel/analytics';



function App() {

  const [darkMode, setDarkMode] = useState(false);

  inject()

  const toggleMode = () => {
    if (darkMode) {
      // light mode
      document.documentElement.style.setProperty('--background', 'white')
      document.documentElement.style.setProperty('--text-primary', 'black')
      document.documentElement.style.setProperty('--text-secondary', '#847f7f')
      document.documentElement.style.setProperty('--accent', '#40445c')
      document.documentElement.style.setProperty('--footer-title', '#3d3d3d')
      document.documentElement.style.setProperty('--footer-text', '#3d3d3d')
      document.documentElement.style.setProperty('--placeholder', 'rgba(51, 53, 66, 0.23)')
      document.documentElement.style.setProperty('--topic-bg', '#f1f1f5')
      document.documentElement.style.setProperty('--topic-fg', '#9b9999')
      document.documentElement.style.setProperty('--proj-head', '#0f164d')
      document.documentElement.style.setProperty('--proj-head-t', 'rgba(15, 22, 77, 0.07)')
      document.documentElement.style.setProperty('--topic-proj-bg', '#e8eaf2')
      document.documentElement.style.setProperty('--textbody', 'black')
      document.documentElement.style.setProperty('--texttitle', 'rgba(0,0,0,0.8)')
      document.documentElement.style.setProperty('--text-subtitle', 'rgba(0,0,0,0.8)')
      document.documentElement.style.setProperty('--text1', '#0eac0e')

    } else {
      // dark mode
      document.documentElement.style.setProperty('--background', '#191920')
      document.documentElement.style.setProperty('--text-primary', 'white')
      document.documentElement.style.setProperty('--text-secondary', '#abababc8')
      document.documentElement.style.setProperty('--accent', '#9195AF')
      document.documentElement.style.setProperty('--footer-title', '#E8EAF2')
      document.documentElement.style.setProperty('--footer-text', '#7D8097')
      document.documentElement.style.setProperty('--placeholder', 'rgba(145, 149, 175, 0.23)')
      document.documentElement.style.setProperty('--topic-bg', '#24262F')
      document.documentElement.style.setProperty('--topic-fg', '#5B5E71')
      document.documentElement.style.setProperty('--proj-head', '#C2C8F3')
      document.documentElement.style.setProperty('--proj-head-t', 'rgba(194, 200, 243, 0.2)')
      document.documentElement.style.setProperty('--topic-proj-bg', '#272a36')
      document.documentElement.style.setProperty('--textbody', '#ffffffdf')
      document.documentElement.style.setProperty('--texttitle', 'rgba(255,255,255,0.9)')
      document.documentElement.style.setProperty('--text-subtitle', 'rgba(255,255,255,0.8)')
      document.documentElement.style.setProperty('--text1', '#86ff864d')
    }
    setDarkMode(!darkMode);
  }

  return (
    <div>
      <BrowserRouter>
        <Switch>
          <Redirect
              from='/:url*(/+)'
              to={window.location.pathname.slice(0, -1)}
          />
          <Route exact path='/blog'>
            <ScrollToTop />
            <Nav darkMode={darkMode} path="/blog" toggle={toggleMode} />
            <Blog darkMode={darkMode}/>
          </Route>
          <Route exact path='/blog/:id'>
            <ScrollToTop />
            <Nav darkMode={darkMode} toggle={toggleMode} />
            <BlogPost darkMode={darkMode}/>
          </Route>
          <Route exact path="/projects">
            <ScrollToTop />
            <Nav darkMode={darkMode} path="/projects" toggle={toggleMode} />
            <Projects darkMode={darkMode}/>
          </Route>
          <Route exact path="/about">
            <ScrollToTop />
            <Nav darkMode={darkMode} path="/about" toggle={toggleMode} />
            <About darkMode={darkMode}/>
          </Route>
          <Route exact path="/contact">
            <ScrollToTop />
            <Nav darkMode={darkMode} path="/contact" toggle={toggleMode} />
            <Contact darkMode={darkMode}/>
          </Route>
          <Route exact path='/projects/:id'>
            <ScrollToTop />
            <Nav darkMode={darkMode} toggle={toggleMode} />
            <ProjectPost darkMode={darkMode}/>
          </Route>
          <Route exact path="/">
            <ScrollToTop />
            <div className="flare">
              <img className="flare-img" src="Mesh-Grad.png" alt="a sphere"/>
            </div>
            <Nav darkMode={darkMode} toggle={toggleMode} />
            <Main darkMode={darkMode} />
            <Presentation darkMode={darkMode} />
            <TechSlider darkMode={darkMode} />

            <FeaturedBlogList darkMode={darkMode} />
            <FeaturedProjectList darkMode={darkMode} />

          </Route>
        </Switch>
        <Footer darkMode={darkMode} />
        <Analytics/>
      </BrowserRouter>
    </div>
  );
}

export default App;
