import { Link } from 'react-router-dom';
import BlogCard from '../BlogCard';
import useContentful from '../../../hooks/useContentful';
import { useState, useEffect } from 'react';

import './style.css'

function FeaturedBlogList(props) {
  const [blogs, setBlogs] = useState([]);
  const { getFeaturedBlogs } = useContentful();
  const [isLoading, setLoading] = useState(true);


  useEffect(() => {
    if(isLoading) {
      getFeaturedBlogs()
          .then((response) => setBlogs(response)).then(setLoading(false));
  }
  }, []);

  var options = { weekday: 'short', year: 'numeric', month: 'long', day: 'numeric' };

  return (
    <div className="Blog-rec">
      <div className="header">
        <div className="header-title">
          <div className="header-title-1">
          Blog recommendations
          </div>
          <div className="header-title-2">
          Small projects and reflexions.
          </div>
        </div>
        <Link to="/blog" className="pres-text-arrow-blog-list">
            <div className="main-title-learn-more-text-blog-list">
              See the blog
            </div>
            <img src={props.darkMode ? "arrow-down-btn.svg" : "arrow-down-btn-light.svg"} alt=""/>
        </Link>
      </div>
      <div className="event-list">
        { blogs
          ?
          blogs.map((e,key) =>
            <BlogCard e={e} featured={true} imgname={e.image.file.url} key={key} date={new Date(e.date).toLocaleDateString("en-UK", options)} title={e.title} />
          )
          :
          null
        }

      </div>
    </div>
  );
}

export default FeaturedBlogList;
