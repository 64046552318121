import './style.css'

function BlogMain(props) {
    return (
        <div className="main-blog">
            <div className="main-blog-title">
                <div className="main-blog-title-header">
                    {props.title}
                </div>
                <div className="main-blog-title-subheader">
                    {props.subtitle}
                </div>
                {/* { props.search === true
                    ? <div className="main-blog-title-input">
                        <img src={props.darkMode ? "lupa.png" : "lupa-light.png"} alt="magnifying glass" />
                        <input type="text" id="name" name="name" placeholder='Search posts'></input>
                    </div>
                    : null
                } */}
            </div>
            <div className="main-blog-img">
                <img loading="lazy" src={props.img} alt="" />
            </div>
        </div>
    );
}

export default BlogMain;