import { Link, useRouteMatch } from 'react-router-dom';

import './style.css'

function FeaturedBlogCard(props) {

  const { url } = useRouteMatch();

  let divStyle = {
    border: "0px solid var(--text-secondary)",
    background: 'url(' + props.imgname + ') center center / cover',
    borderRadius: "10px",
  };
  
  return (
    <Link className={props.featured === true ? "event-card" : "event-card-blog"} to={{ 
      pathname:`/blog/${props.title.replaceAll(' ','-').toLowerCase()}`,
      state: {e: props.e, date: props.date},
    }}>
    {/* <div className={props.featured === true ? "event-card" : "event-card-blog"}>   */}
        <div className="event-card-img" style={divStyle}>
            <div className="event-ring">

            </div>
        </div>
        <div className={props.featured === true ? "event-card-desc-featured" : "event-card-desc"}>
          { props.date 
            ? 
            <div className="event-card-desc-date">
              {props.date}
            </div>
            :
            null
          }
          <div className="event-card-desc-title">
            {props.title}
          </div>
        </div>
    {/* </div> */}
    </Link>
  );
}

export default FeaturedBlogCard;
