import './style.css'
import { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

function Nav(props) {


  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const [toggleBullet, setBulletList] = useState(false);

  useEffect(() => {
    function handleResize() {
      if(getWindowDimensions().width > 931)
        setBulletList(false);
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleToggle = () => {
    props.toggle()
  }

  const handleBulletClick = () => {

    if(!toggleBullet) {
      document.getElementsByTagName("html")[0].style.position = "fixed";
      document.getElementsByTagName("body")[0].style.position = "fixed";
      document.getElementsByTagName("html")[0].style.overflow = "hidden";
      // document.getElementsByTagName("body")[0].style.overflow = "hidden";
      document.getElementsByClassName("nav")[0].style.width = "90vw";
    } else {
      document.getElementsByTagName("html")[0].style.overflow = "auto";  
      // document.getElementsByTagName("body")[0].style.overflow = "hidden";
      document.getElementsByTagName("html")[0].style.position = "static";
      document.getElementsByTagName("body")[0].style.position = "static";
      document.getElementsByClassName("nav")[0].style.width = "auto";
    }

    setBulletList(!toggleBullet);
  }


  return (
    <>
    <div className="nav">
      <div className="nav-title">
        <Link to="/" className="a" onClick={toggleBullet ? handleBulletClick : null} >
          Cristian Gutiérrez
        </Link>
      </div>
        { windowDimensions && windowDimensions.width < 931
          ?
          <>
            <div className="nav-links">
            </div>
            <div className="nav-imgs">
              <img src={props.darkMode ? "bullet-list.svg" : "bullet-list-light.svg"} alt="button to toggle night or light web theme" loading='lazy' onClick={handleBulletClick}/>
            </div>
          </>
          :
          <div className="nav-right"> 
            <div className="nav-links">
              <Link to="/blog" className={props.path === "/blog" ? 'btn_activated' : 'a'}>Blog</Link>
              <Link to="/projects" className={props.path === "/projects" ? 'btn_activated' : 'a'} >Projects</Link>
              <Link to="/about" className={props.path === "/about" ? 'btn_activated' : 'a'} >About</Link>
              <Link to="/contact" className={props.path === "/contact" ? 'btn_activated' : 'a'} >Contact</Link>
              {/* <img src="theme.svg" alt="button to toggle night or light web theme"/> */}
            </div>
            <div className="nav-imgs">
                <img src={props.darkMode ? "theme.svg" : "theme-light.svg"} className="svg-theme" alt="button to toggle night or light web theme" loading='lazy' onClick={handleToggle}/>
            </div>
          </div>
        }
    </div>
    { toggleBullet && windowDimensions.width < 931
      ?
      <div className="blocking">
        <div className="bullet-list">
          <Link to="/blog" onClick={handleBulletClick}>Blog</Link>
          <Link to="/projects" onClick={handleBulletClick}>Projects</Link>
          <a href="/">About</a>
          <a href="/">Contact</a>
          <div className="nav-imgs">
              <img src={props.darkMode ? "theme.svg" : "theme-light.svg"} className="svg-theme" alt="button to toggle night or light web theme" loading='lazy' onClick={handleToggle}/>
          </div>
        </div>
      </div>
      :
      <></>
    }
    </>
  );
}
  
export default Nav;