import { createClient } from "contentful";

const useContentful = () =>  {
    const client = createClient({
        space: process.env.REACT_APP_CONTENTFUL_SPACEID,
        accessToken: process.env.REACT_APP_CONTENTFUL_DELIVERY_TOKEN,
        host: "cdn.contentful.com"
    })

    const getFeaturedBlogs = async () => {
        try {
            const entries = await client.getEntries({
                content_type: "blogPost",
                select: "fields",
                // order: "fields.date",
                "fields.featured": "true"
            })
            const sanitizedEntries = entries.items.map((item) => {
                const image = item.fields.image.fields

                return {
                    ...item.fields,
                    image
                }
            })
            return sanitizedEntries;
        } catch(error) {
            console.log("error fetching featured blog posts: ", error)
        }
    };

    const getFeaturedProjects = async () => {
        try {
            const entries = await client.getEntries({
                content_type: "projectPost",
                select: "fields",
                // order: "fields.date",
                "fields.featured": "true"
            })
            const sanitizedEntries = entries.items.map((item) => {
                const image = item.fields.image.fields

                return {
                    ...item.fields,
                    image
                }
            })

            return sanitizedEntries;
        } catch(error) {
            console.log("error fetching featured blog posts: ", error)
        }
    };

    const getAllBlogs = async () => {
        try {
            const entries = await client.getEntries({
                content_type: "blogPost",
                select: "fields",
                order: "fields.date",
            })
            const sanitizedEntries = entries.items.map((item) => {
                const image = item.fields.image.fields
                const topics = item.fields.topics

                return {
                    ...item.fields,
                    image,
                    topics
                }
            })
            return sanitizedEntries.reverse()
        } catch(error) {
            console.log("error fetching blog posts: ", error)
        }
    };

    const getAllProjects = async () => {
        try {
            const entries = await client.getEntries({
                content_type: "projectPost",
                select: "fields",
            })
            const sanitizedEntries = entries.items.map((item) => {
                const image = item.fields.image.fields
                const topics = item.fields.topics

                return {
                    ...item.fields,
                    image,
                    topics
                }
            })
            return sanitizedEntries.reverse();
        } catch(error) {
            console.log("error fetching blog posts: ", error)
        }
    };

    const getBlogPost = async (name) => {
        try {
            const entries = await client.getEntries({
                content_type: "blogPost",
                select: "fields",
                "fields.slug": name
            })
            const sanitizedEntries = entries.items.map((item) => {
                console.log("item blog: ", item)
                const image = item.fields.image.fields.file.url
                const topics = item.fields.topics

                return {
                    ...item.fields,
                    image,
                    topics
                }
            })
            console.log("ENTRIES: ", sanitizedEntries)
            return sanitizedEntries.reverse();
        } catch(error) {
            console.log("error fetching blog post: ", error)
        }
    };

    const getProjectPost = async (name) => {
        try {
            const entries = await client.getEntries({
                content_type: "projectPost",
                select: "fields",
                "fields.slug": name
            })
            const sanitizedEntries = entries.items.map((item) => {
                const image = item.fields.image.fields.file.url
                const topics = item.fields.topics

                return {
                    ...item.fields,
                    image,
                    topics
                }
            })
            return sanitizedEntries.reverse();
        } catch(error) {
            console.log("error fetching blog post: ", error)
        }
    };

    return { getFeaturedBlogs, getFeaturedProjects, getAllBlogs, getAllProjects, getBlogPost, getProjectPost }
}

export default useContentful;