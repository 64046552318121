import BlogMain from "../../components/blog/BlogMain";
import Topics from "../../components/Topics";
import ProjectList from "../../components/projects/ProjectList";
import useContentful from '../../hooks/useContentful';
import { useState, useEffect } from 'react';

import './style.css';

function Projects(props) {
    const [projs, setProjs] = useState([]);
    const { getAllProjects } = useContentful();
    const [isLoading, setLoading] = useState(true);
    const [topicsSorted, setTopics] = useState([]);
    const [btnClicked, setBtnClicked] = useState('');

    useEffect(() => {
        document.title = 'Projects | Cristian Gutiérrez Portfolio website';
    }, []);

    useEffect(() => {
        if(isLoading) {
            getAllProjects()
                .then((response) => {
                let topiclist = []
                response.forEach((element, k) => {
                    topiclist.push.apply(topiclist, element.topics);
                });

                const counts = Object.entries(
                    topiclist.reduce((r, s) => {
                        r[s] = (r[s] || 0) + 1;

                        return r;
                    }, {})
                ).sort(([, a], [, b]) => b - a);

                setTopics(['all', ...counts.map(([s]) => s)])
                setProjs(response);
                }).then(setLoading(false));
        }
    });

    const handleBtnState = (name) => {
        setBtnClicked(name);
    }

    return (
        <div className="blog">
            <BlogMain
                title="Projects Showcase."
                subtitle="Learn by building something."
                search={false}
                img="./icon-lightining.png"
                darkMode={props.darkMode}
            />
            <Topics darkMode={props.darkMode} title="Search by Technology" topics={topicsSorted} btnState={handleBtnState} />
            {/* <div className="topics-flex">
                <Topics style={{flexBasis: '33.33%' }} title="Search also by topic" topics={topicsSorted}/>
                <Topics style={{flexBasis: '66.66%' }} title="Search also by topic" topics={topicsSorted}/>
            </div> */}
            <ProjectList projs={projs} btnClicked={btnClicked} darkMode={props.darkMode} />
        </div>
    );
}

export default Projects;