import './style.css'

function Topics(props) {
    return (
        <div className={props.btn === props.label ? "topicSelected" : "topic"} onClick={() => props.handle(props.label)}>
            {props.label}
        </div>
    );
}

export default Topics;