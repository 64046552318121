import { Link } from 'react-router-dom';

import './style.css'

function Footer(props) {

    return (
        <>
            <hr className="hr-footer" />
            <div className="footer">
                <div className="footer-cristian">
                    <h1 className="footer-title">
                        Cristian Gutiérrez
                    </h1>
                    <div className="footer-title-body">
                    Barcelona, Spain 08400<br/>
                    GMT +2
                    <img src={props.darkMode ? "firma.png" : "firma-black.png"} alt="Cristian signature" />
                    </div>
                </div>
                <div className="footer-links">
                    <div className="footer-contact">
                        <h1 className="footer-title">
                            Contact
                        </h1>
                        <div className="footer-title-body">
                        <a href="mailto:ggcristian@icloud.com">Email Cristian</a><br/>
                        <br/>
                        <a href="https://www.linkedin.com/in/cgutierrz/" target="_blank" rel="noreferrer">LinkedIn</a><br/>
                        <a href="https://github.com/ggcr" target="_blank" rel="noreferrer">GitHub</a><br/>
                        <a href="">Resume</a><br/>
                        </div>
                    </div>
                    <div className="footer-sitemap">
                        <h1 className="footer-title">
                            Sitemap
                        </h1>
                        <div className="footer-title-body">
                        <Link to="/">Home</Link><br/>
                        <Link to="/blog">Blog</Link><br/>
                        <Link to="/projects">Projects</Link><br/>
                        <Link to="/about">About</Link><br/>
                        <Link to="/contact">Contact</Link><br/>
                        </div>
                    </div>
                </div>
                <div className="footer-title-text">
                This website was made from scratch by Cristian Gutiérrez. This is not a template nor a theme. You can check out the Project overview as well as the  113 Commits made on the Source Repository.
                </div>
            </div>
        </>
    );
}

export default Footer;