import FeaturedProjectCard from '../FeaturedProjectCard';

import './style.css';

export default function ProjectList(props) {


    var data;

    if(props.btnClicked === '' || props.btnClicked === 'all') {
        data = props.projs
    } else {
        data = props.projs.filter(e => e.topics.includes(props.btnClicked) === true);
    }
    

    return(
        <div className="project-list" >  
            { data
                ? 
                data.map((e,key) =>
                    <FeaturedProjectCard e={e} imgname={e.image.file.url} key={key} title={e.title} descr={e.desc} cat={e.cat}/>
                )
                :
                null
            }
            
        </div>
    );
}