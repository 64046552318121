import { useState } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import './style.css'

function FeaturedProjectCard(props) {
    const { url } = useRouteMatch();

    console.log("URL: ", url)

    const [expandProj, setExpandProj] = useState(false);

    const divStyle = {
        background: 'url(' + props.imgname + ') center center / cover',
        borderRadius: "10px",
    };

    const expandCurrentProject = () => {
        if(!expandProj === true) {
            document.getElementById("scroll").scrollLeft = 0;
        }
        setExpandProj(!expandProj);
    }

    var options = { weekday: 'short', year: 'numeric', month: 'long', day: 'numeric' };


    return (
        <Link className="proj-card-desc" id="scroll" style={divStyle} onClick={expandCurrentProject} to={{ 
            pathname:`/projects/${props.title.replaceAll(' ','-').toLowerCase()}`,
            state: {e: props},
          }}>
            <div className="proj-card-desc-top">
                { props.cat
                    ? 
                    <div className="proj-card-desc-cat">
                        {props.cat}
                    </div>
                    : null
                }
                <div className="proj-card-desc-title">
                {props.title}
                </div>
            </div>
            <div className="proj-card-desc-bottom">
                <div className="proj-card-desc-sub">
                {props.descr}
                </div>
            </div>
        </Link>
    );
}

export default FeaturedProjectCard;