import Topics from '../../components/Topics';
import BlogList from '../../components/blog/BlogList';
import BlogMain from '../../components/blog/BlogMain';
import useContentful from '../../hooks/useContentful';
import { useState, useEffect } from 'react';

export function Blog(props) {
    const [blogs, setBlogs] = useState([]);
    const { getAllBlogs } = useContentful();
    const [isLoading, setLoading] = useState(true)
    const [topicsSorted, setTopics] = useState([]);
    const [btnClicked, setBtnClicked] = useState('');

    useEffect(() => {
        if(isLoading) {
        getAllBlogs()
            .then((response) => {
            let topiclist = []
            response.forEach((element, k) => {
                topiclist.push.apply(topiclist, element.topics);
            });

            const counts = Object.entries(
                topiclist.reduce((r, s) => {
                    r[s] = (r[s] || 0) + 1;

                    return r;
                }, {})
            ).sort(([, a], [, b]) => b - a);

            setTopics(['all', ...counts.map(([s]) => s)])
            setBlogs(response);
            }).then(setLoading(false));
        }
    });

    useEffect(() => {
        document.title = 'Blog Posts | Cristian Gutiérrez Portfolio website';
    }, []);

    const handleBtnState = (name) => {
        setBtnClicked(name);
    }

    return (
        <div className="blog">
            <BlogMain
                title="Surf the blog."
                subtitle="Technical writting, small cap projects and reflexions."
                search={true}
                img="./icon-blog.png"
                darkMode={props.darkMode}
            />
            <Topics darkMode={props.darkMode} title="Search by Topics" topics={topicsSorted} btnState={handleBtnState} />
            <BlogList blogs={blogs} btnClicked={btnClicked} />
        </div>
    );
}

export default Blog;