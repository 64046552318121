import { useLocation } from 'react-router-dom';
import useContentful from '../../../hooks/useContentful';
import { useEffect, useState } from 'react';

import './style.css'

function handleRichText(data, currentList) {
    data.forEach((element) => {
      if (element.content && element.content.length > 0) {
        return handleRichText(element.content, currentList);
      } else {
        switch (element.nodeType) {
          case "text":
            return (element.marks && element.marks.length > 0)
            ? currentList.push([element.value, "text", element.marks[0].type])
            : currentList.push([element.value, "text"]);
          case "embedded-asset-block":
            return currentList.push([element.data.target.fields.file.url, "img"]);
          default:
            return currentList;
        }
      }
    });
    return currentList;
}

export default function BlogPost(props) {
    const [post, setPost] = useState([]);
    const [postBody, setPostBody] = useState([]);
    const [isLoading, setLoading] = useState(true)
    const [topicsSorted, setTopics] = useState([]);
    const { getBlogPost } = useContentful();

    const slug = window.location.pathname.split('/')[2];

    useEffect(() => {
        if(isLoading) {
            getBlogPost(slug)
            .then(
                (response) => {
                    setPostBody(handleRichText(response[0].body.content, []));
                    setPost(response[0])
                })
                .then(
                    document.title = (postRoute !== null) ? postRoute.e.title + ' | Cristian Gutiérrez Portfolio website' : post.title + ' | Cristian Gutiérrez Portfolio website'
                )
                .then(
                    setLoading(false)
                );
        }
    });

    var options = { weekday: 'short', year: 'numeric', month: 'long', day: 'numeric' };

    console.log("post: ", post)

    let postRoute = null;
    try {
        // fetch post from the router-link
        let location = useLocation();
        localStorage.setItem("e", JSON.stringify(location.state));
        postRoute = JSON.parse(localStorage.getItem("e"));
    } catch(err) {
        // fetch post from the CMS
        postRoute = null
    }


    let out = null;
    if(postRoute !== null) { out = null }
    else { out = null }

    return (
        <div className="blogPost">
            <div className="blogPost-header">
                <div className='blogPost-header-title'>
                    { postRoute !== null
                        ? postRoute.e.title
                        : post.title
                    }
                </div>
                <div className="blogPost-header-img">
                    { postRoute !== null
                        ? <img src={postRoute.e.image.file.url} />
                        : <img src={post.image} />
                    }
                </div>
            </div>
            <div className="blogPost-body">
                <div className="blogPost-header-sub">
                    <div className="blogPost-header-sub-pair">
                        { postRoute !== null
                        ?   <>
                            <div>
                                Date
                            </div>
                            <div>
                                {postRoute.date}
                            </div>
                            </>
                        :  <>
                            <div>
                                Date
                            </div>
                            <div>
                                {new Date(post.date).toLocaleDateString("en-UK", options)}
                            </div>
                            </>
                        }
                    </div>
                    <div className="blogPost-header-sub-pair">
                    { postRoute !== null
                        ?   <>
                            <div>
                                Keywords
                            </div>
                            <div>
                                { postRoute.e.topics.map((rank,i,row) => {
                                    if (i + 1 === row.length) {
                                        return <span className="span-topics" key={i}>{rank.charAt(0).toUpperCase() + rank.slice(1)
                                        }</span>
                                    } else {
                                        return <span className="span-topics" key={i}>{rank.charAt(0).toUpperCase() + rank.slice(1)
                                        },&nbsp;</span>
                                    }
                                })
                                }
                            </div>
                            </>
                        : <>
                            <div>
                                Keywords
                            </div>
                            <div>
                                { post.topics && post.topics.map((rank,i,row) => {
                                    if (i + 1 === row.length) {
                                        return <span className="span-topics" key={i}>{rank.charAt(0).toUpperCase() + rank.slice(1)
                                        }</span>
                                    } else {
                                        return <span className="span-topics" key={i}>{rank.charAt(0).toUpperCase() + rank.slice(1)
                                        },&nbsp;</span>
                                    }
                                })
                                }
                            </div>
                            </>
                        }
                    </div>
                </div>
                <div className="body-text">
                {
                postBody && postBody.map((element) => {
                    switch(element[1]) {
                      case "text":
                        if(element.length > 2) {
                            switch(element[2]) {
                                case "underline":
                                case "bold":
                                    return <div className='text-bold'>{element[0]}</div>;
                                case "code":
                                    return <div className='text-code'>{element[0]}</div>;
                                default:
                                    return <div className='text'>{element[0]}</div>;
                            }
                        }
                        return <div className='text'>{element[0]}</div>;
                      case "img":
                          return <div className='asset'><img className='asset-img' src={element[0]} /></div>;
                      default:
                          return <></>
                    }

                  })
                }
            </div>
            </div>

        </div>
    );
}