
import './style.css'
import { useEffect } from 'react';

export default function Contact(props) {
    useEffect(() => {
        document.title = 'Contact me | Cristian Gutiérrez Portfolio website';
    }, []);
    return(
        <div className="contact">
            <a href="mailto:ggcristian@icloud.com" rel="noreferrer">ggcristian@icloud.com</a><br/>
            <a href="https://www.linkedin.com/in/cgutierrz/" target="_blank" rel="noreferrer">LinkedIn</a><br/>
            <a href="https://github.com/ggcr" target="_blank" rel="noreferrer">GitHub</a><br/>
            <a href="">Resume ↓</a><br/>
        </div>
    );
}