import FeaturedProjectCard from '../FeaturedProjectCard';
import { Link } from 'react-router-dom';
import useContentful from '../../../hooks/useContentful';
import { useState, useEffect } from 'react';
import './style.css'

function FeaturedProjectList(props) {
    const [projs, setProjs] = useState([]);
    const { getFeaturedProjects } = useContentful();
    const [isLoading, setLoading] = useState(true);


    useEffect(() => {
        if(isLoading) {
            getFeaturedProjects()
            .then((response) => setProjs(response)).then(setLoading(false));
    }
    });

    return(
        <div className="Blog-rec-proj">
            <div className="header">
                <div className="header-title">
                <div className="header-title-1">
                    Featured Projects
                </div>
                <div className="header-title-2">
                    Learn by doing. Adapt fast.
                </div>
                </div>
                <Link to="/projects" className="pres-text-arrow-blog-list">
                    <div className="main-title-learn-more-text-blog-list">
                    See all projects
                    </div>
                    <img src={props.darkMode ? "arrow-down-btn.svg" : "arrow-down-btn-light.svg"} alt="image of a sphere"/>
                </Link>
            </div>
            <div className="proj-list" >
                { projs
                    ?
                    projs.map((e,key) =>
                        <FeaturedProjectCard e={e} cat={e.cat} imgname={e.image.file.url} key={key} title={e.title} descr={e.desc} />
                    )
                    :
                    null
                }

            </div>
        </div>
    )
}

export default FeaturedProjectList;