

import './style.css'
import { useEffect } from 'react';

export default function About(props) {

    useEffect(() => {
        document.title = 'About | Cristian Gutiérrez Portfolio website';
    }, []);
    return (
        <div className="about">
            <div className="vid">
                <video autoPlay loop playsinline muted>
                    <source src="Render.mp4" type="video/mp4" />
                </video>
            </div>

            <div className="grid-container">
                <div class="item4">
                    <h1>PAST</h1>
                    <p>I was born in Barcelona and raised in a town 1 hour away from the big city. Since I was little I started playing with computers, went to college to pursue a <span>Computer Science degree</span> which gave me really good solid foundations.</p>
                    <p>During the 4 years of the degree, I took advantage of the summers to learn stuff that interested me. </p>
                    <p>The <span>first summer</span> I learned the basics of the web with vanilla JavaScript, HTML and CSS.</p>
                    <p>The <span>second summer</span> I went deeper with web and design and learned cool tools such as Figma, and built some nice cool websites!</p>
                    <p>The <span>third summer</span> I applied the theoretical concepts of the university and implemented TCP/IP servers from scratch with C and Go which allowed me to land an <span>internship</span> at the R&D department at LOGITEK, a smart cities company.</p>
                    <p>The <span>last summer</span> while I was doing a full-stack website with API fetching, DB storing and a couple of content filters, I quickly realized why JS frameworks are so popular and so I jumped into <span>ReactJS</span>.</p>
                </div>
                {/* <div class="text1">
                2000,  BARCELONA  WEB  UAB     DATA  <br/>  DESIGN <br/>    ⮕        TECH*
                </div> */}
                <div class="text1">
                2000   BARCELONA   UAB   BSC   DATA   CREATIVE-<br/>    <span style={{float:'right', margin:'0 2vw'}}>DESIGN</span>
                </div>
                <div class="item5">
                    <h1>PRESENT</h1>
                    <p>At the moment I am invested at my job as a <span>Junior Research Engineer</span> at the Barcelona Supercomputing Center (BSC) building internal tooling. I like a lot the mindset of the company as well as the projects I am able to see. </p>
                    <p>I keep playing with the Web and React on my free spare time, this website is an example of that. I am also exploring 3d rendering on the web and some full-stack frameworks that I hope to make new projects soon.</p>
                    <p>During the start of 2023 I will be doing my own <span>research project</span> for the Computer Science degree with BSC!</p>
                    <div class="text3">
                        ☺
                    </div>
                </div>
                <div class="text2">
                     re1<br/>@ bsc.
                </div>
                <div class="item6">
                    <h1>FUTURE</h1>
                    <p>You never know what the future holds but I know that I want to continue learning everything that catches my attention and escaping the rabbit hole of tutorials by doing toy projects.</p>
                    <p>Academically, I would like to do a Masters on those subjects that I enjoyed during my degree such as <span>Computer Architecture</span> and <span>Performance</span>, <span>Computer Vision</span>, or even subjects that I learned personally as <span>UI/UX Design</span>.</p>
                    <p>Develop my soft skills, keep meeting people and always contribute to cool projects.</p>
                    <p>If I had to make predictions, I think that we will continue to advocate for <span>Edge technology</span> where the big cloud providers will get closer and closer to the end client, and therefore I think that the power will be (even more) in the <span>Front-End</span>. </p>
                    <p>We will see the raise of whole new level of <span>graphic elements</span> on the web with the rising of new libraries of WebGL and the raise of WebAssembly.</p>
                </div>
                <div class="text4">
                    V
                </div>
                {/* <div class="text4">
                    ⮕
                </div>  */}
            </div>

            {/* <div class="text3">
                    ☺
            </div> */}

        </div>
    );
}