import { Link } from 'react-router-dom';
import { useEffect } from 'react';

import './style.css'

function Main(props) {
    useEffect(() => {
        document.title = 'Cristian Gutiérrez Portfolio website';
    }, []);

  return (
    <div className="main">
        <div className="main-title">
            <div className="main-title-text">
                {/* <span className="br">Current student and developer,</span><br/><span className="br">driven by design and data.</span> */}
                <h1>Current student and developer, driven by design and data.</h1>
                <div className="desc-h3">
                <h3>RESEARCH INTERN AT <a className="link-bsc" href="http://www.cvc.uab.es/" target="_blank" >Computer Vision Center (CVC)↗</a></h3>
                </div>
            </div>
            <div className="main-title-buttons">
                <Link to="/blog">
                    <button className="main-title-buttons-blog">
                        Read the blog
                    </button>
                </Link>
                <Link to="/projects">
                    <button className="main-title-buttons-proj">
                        Projects
                    </button>
                </Link>
            </div>
            {/* <a href="#learnmorecris">
            <div className="main-title-learn-more">
                <img src={props.darkMode ? "arrow-down-btn.svg" : "arrow-down-btn-light.svg"} alt="a cover"/>
                <div id="learnmorecris"  className="main-title-learn-more-text">
                    Learn more about Cristian
                </div>
            </div>
            </a> */}
        </div>
        {/* <div className="main-img">
            <img loading="lazy" src="main1.png" alt="a sphere"/>
        </div> */}
        <div className="dwn-arrow">
            <img loading="lazy" src={props.darkMode ? "arrow-down.png" : "arrow-down-light.png"} />
            <p>Learn more about Cristian</p>
        </div>
    </div>
  );
}

export default Main;
